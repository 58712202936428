import { LoaderFunctionArgs, json } from "@netlify/remix-runtime";
import { MetaFunction, useLoaderData, useRouteError } from "@remix-run/react";
import { staticHeaders } from "~/lib/cache/static-headers";

import { getPageBlocks, getPageBySlug } from "~/lib/cms/cms";
import { BlockFactory } from "~/blocks/block-factory";
import { Seo } from "~/components/seo-page";
import { pageMeta } from "~/lib/page-meta";
import { ErrorBoundaryy } from "~/templates/error-boundary";

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const headers = await staticHeaders(request);
  const page = await getPageBySlug("/");

  return json({ context, page }, { headers });
};

export const meta: MetaFunction = pageMeta;

export default function Index() {
  const { page } = useLoaderData<typeof loader>();
  const blocks = getPageBlocks(page);

  return (
    <>
      {/* <Seo page={page} /> */}
      <main className="">
        <BlockFactory blocks={blocks} />
      </main>
    </>
  );
}

export const ErrorBoundary = ErrorBoundaryy;
